import React from 'react'
import './LoadingSpinner.css';

function LoadingSpinner() {
  return (
    <div className="loading-spinner-container">
    <div className="loading-spinner"></div>
  </div>
  )
}

export default LoadingSpinner